body,
html {
  overflow-x: hidden;
}

.home {
  background: $header-bg;
  background-repeat-y: no-repeat;
  background-position: 0 -50px;
  padding-top: 103px;
  @media (max-width: 991px) {
    padding-top: 71px;
  }
}

.main-banner {
  padding: 4.2rem 0 17rem 0;
  color: $white;
  background-size: cover;
  @media (max-width: 991px) {
    padding: 1rem;
    margin-bottom: 50px;
  }
  .banner-title {
    width: 70%;
    @media (max-width: 991px) {
      width: 100%;
    }
  }
  & p {
    width: 95%;
    @media (max-width: 991px) {
      width: 100%;
    }
  }
}

section {
  padding: 120px 0 0 0;
  @media (max-width: 1024px) {
    padding: 130px 0 0 0;
  }
  @media (max-width: 991px) {
    padding: 70px 0 0 0;
  }
}

.services-box {
  padding: 10px 100px 40px 0;
  @media (max-width: 991px) {
    padding: 10px 10px 40px 0;
  }
  &:hover {
    img {
      -webkit-filter: grayscale(100%);
      /* Safari 6.0 - 9.0 */
      filter: grayscale(100%);
      -webkit-transition: all 2s;
      -moz-transition: all 2s;
      -ms-transition: all 2s;
      -o-transition: all 2s;
      transition: all 2s;
    }
  }
}

.tick-icon {
  width: 20px;
  height: 20px;
}

.card {
  border: 0;
  margin-bottom: 30px;
  margin-top: 30px;
  border-radius: 6px;
  color: rgba(0, 0, 0, 0.87);
  background: #fff;
  width: 100%;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
}

.card-plain {
  background: transparent;
  box-shadow: none;
}

.card-profile,
.card-testimonial {
  margin-top: 30px;
  text-align: center;
}

.card .card-header {
  border-radius: 3px;
  padding: 1rem 15px;
  margin-left: 15px;
  margin-right: 15px;
  margin-top: -30px;
  border: 0;
  background-color: rgba(0, 0, 0, 0);
}

.card-plain .card-footer {
  padding-left: 5px;
  padding-right: 5px;
  background-color: transparent;
}

.card .card-body + .card-footer {
  padding-top: 0;
  border: 0;
  border-radius: 6px;
}

.card .card-header.card-header-image .colored-shadow {
  transform: scale(0.94);
  top: 12px;
  filter: blur(12px);
  position: absolute;
  width: 100%;
  height: 100%;
  background-size: cover;
  z-index: -1;
  transition: opacity 0.45s;
  opacity: 0;
}

.card .card-header.card-header-image {
  position: relative;
  padding: 0;
  z-index: 1;
  margin-left: 15px;
  margin-right: 15px;
  margin-top: -30px;
  border-radius: 6px;
  padding: 0px 80px;
}

.colaboradores .card .card-header.card-header-image {
  padding: 0px 40px;
}

.colaboradores .card .card-body h4 {
  font-size: 1.275rem;
}
.card .card-header.card-header-image a {
  display: block;
}

.card .card-header.card-header-image .colored-shadow {
  transform: scale(0.94);
  top: 12px;
  filter: blur(12px);
  position: absolute;
  width: 100%;
  height: 100%;
  background-size: cover;
  z-index: -1;
  transition: opacity 0.45s;
  opacity: 0;
  border-radius: 50%;
}

.card .card-header.card-header-image img {
  width: 100%;
  border-radius: 50%;
  pointer-events: none;
  box-shadow: 0 5px 15px -8px rgba(0, 0, 0, 0.24),
    0 8px 10px -5px rgba(0, 0, 0, 0.2);
}

.btn.btn-fab .fa,
.btn.btn-fab .material-icons,
.btn.btn-just-icon .fa,
.btn.btn-just-icon .material-icons {
  margin-top: 0;
  position: absolute;
  width: 100%;
  transform: none;
  left: 0;
  top: 0;
  height: 100%;
  line-height: 41px;
  font-size: 20px;
}

.card-profile .card-footer .btn.btn-just-icon,
.card-testimonial .card-footer .btn.btn-just-icon {
  font-size: 20px;
  padding: 12px;
  line-height: 1em;
}

.btn.btn-fab,
.btn.btn-just-icon {
  font-size: 24px;
  height: 41px;
  min-width: 41px;
  width: 41px;
  padding: 0;
  overflow: hidden;
  position: relative;
  line-height: 41px;
}

.btn.btn-linkedin {
  color: #fff;
  background-color: #0976b4;
  border-color: #0976b4;
  box-shadow: 0 2px 2px 0 rgba(9, 118, 180, 0.14),
    0 3px 1px -2px rgba(9, 118, 180, 0.2), 0 1px 5px 0 rgba(9, 118, 180, 0.12);
}

.btn.btn-facebook {
  color: #fff;
  background-color: #3b5998;
  border-color: #3b5998;
  box-shadow: 0 2px 2px 0 rgba(59, 89, 152, 0.14),
    0 3px 1px -2px rgba(59, 89, 152, 0.2), 0 1px 5px 0 rgba(59, 89, 152, 0.12);
}

.btn.btn-dribbble {
  color: #fff;
  background-color: #ea4c89;
  border-color: #ea4c89;
  box-shadow: 0 2px 2px 0 rgba(234, 76, 137, 0.14),
    0 3px 1px -2px rgba(234, 76, 137, 0.2), 0 1px 5px 0 rgba(234, 76, 137, 0.12);
}

.btn.btn-twitter {
  color: #fff;
  background-color: #55acee;
  border-color: #55acee;
  box-shadow: 0 2px 2px 0 rgba(85, 172, 238, 0.14),
    0 3px 1px -2px rgba(85, 172, 238, 0.2), 0 1px 5px 0 rgba(85, 172, 238, 0.12);
}

.btn.btn-blue {
  color: #fff;
  background-color: #000000;
  width: 80px;
  height: 80px;
}

.btn.btn-blue.btn-just-icon .fa {
  line-height: 78px;
  font-size: 46px;
}

.eventos {
  padding: 105px 0px 0px 0px;
}

.eventos .eventos-container {
  background-color: #000000;
  color: white;
  padding-top: 40px;
  @media (max-width: 991px) {
    padding: 40px;
  }
  & a {
    float: none !important;
    padding-left: 5px;
  }
}

.eventos a {
  color: #23B2C6;
}